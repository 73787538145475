.dd-container {
	width: 100% !important;
	margin: $gutter 0;

	.dd-select {
		width: 100% !important;
		border-radius: 4px;
		border: 4px solid #a3d2f3;

		.dd-selected-text {
			left: 100px;
			position: relative;
		}

		img {
			max-width: 80px;
		}

		.dd-pointer-up {
			margin-top: -11px;
			border: solid 7px transparent !important;
			border-bottom: solid 8px #2c6090 !important;
		}

		.dd-pointer-down {
			border: solid 7px transparent;
			border-top: solid 8px #2c6090;
			margin-bottom: -5px;
		}
	}

	.dd-options {
		width: 100% !important;
		position: relative;

		.dd-option {
			background-color: white;
			padding: $gutter/2 $gutter !important;
			margin: 0;

			img {
				margin-right: $gutter;
				height: 20px;
				max-width: 80px;
				position: absolute;
			}

			.dd-option-text {
				text-align: left;
				line-height: 20px !important;
				margin-bottom: 0;
				position: relative;
				left: 100px;
				font-family: $font-tungsten-medium;
				font-weight: 100;
			}
		}
	}
}

#seasonDropdown  {
	.dd-option-text {
		left: 0;
	}

	.dd-selected-text {
		left: 0;
	}
}

.dd-selected {
	padding: $gutter/2 $gutter !important;
	margin: 0;

	img {
		margin-right: $gutter;
		height: 20px;
		position: absolute;
	}
}

.dd-selected-text {
	color: black;
	text-align: left;
	margin: 0;
	line-height: 20px !important;
	margin-bottom: 0;
	font-family: $font-tungsten-medium;
	font-weight: 100;
}

.dd-pointer {
	right: $gutter !important;
}