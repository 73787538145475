.btn {
	text-transform: uppercase;
	text-decoration: none;
	border-left: solid 2px rgb(170, 146, 85);
	border-right: solid 2px rgb(170, 146, 85);
	border-top: solid 2px rgb(130, 116, 45);
	border-bottom: solid 2px rgb(130, 116, 45);
	background: rgb(191,158,74); /* Old browsers */
	background: -moz-linear-gradient(top, rgba(191,158,74,1) 0%, rgba(220,190,115,1) 15%, rgba(220,190,115,1) 15%, rgba(220,190,115,1) 16%, rgba(220,190,115,1) 43%, rgba(220,190,115,1) 50%, rgba(180,153,69,1) 69%, rgba(180,153,69,1) 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(top, rgba(191,158,74,1) 0%,rgba(220,190,115,1) 15%,rgba(220,190,115,1) 15%,rgba(220,190,115,1) 16%,rgba(220,190,115,1) 43%,rgba(220,190,115,1) 50%,rgba(180,153,69,1) 69%,rgba(180,153,69,1) 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom, rgba(191,158,74,1) 0%,rgba(220,190,115,1) 15%,rgba(220,190,115,1) 15%,rgba(220,190,115,1) 16%,rgba(220,190,115,1) 43%,rgba(220,190,115,1) 50%,rgba(180,153,69,1) 69%,rgba(180,153,69,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#bf9e4a', endColorstr='#b49945',GradientType=0 ); /* IE6-9 */
	border-radius: 3px;
	width: 100%;
	padding: $gutter/2 0;
	text-align: center;
	color: white;
	display: block;
	text-shadow: -1px -1px 0 rgb(170, 146, 85), 1px 1px 0 rgb(170, 146, 85), 1px -1px 0 rgb(170, 146, 85) , -1px 1px 0 rgb(170, 146, 85);
	position: relative;
	font-size: 23px;
	letter-spacing: 3px;
	transition: color $time-fast ease-in-out;
	font-family: $font-tungsten-bold;

	&:hover,
	&:focus,
	&:active {
		color: black;
	}

	&:before {
		content: none;
	}
}