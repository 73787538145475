// ========================================================

// # FONTS

@font-face {
    font-family: 'Iconfont';
    src: url('../assets/fonts/Iconfont.eot');
    src: url('../assets/fonts/Iconfont.woff') format('woff'),
         url('../assets/fonts/Iconfont.ttf') format('truetype'),
         url('../assets/fonts/Iconfont.svg') format('svg');
    font-style: normal;
}

.icon-font {
    font-family: 'Iconfont' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@font-face {
    font-family: 'Tungsten Black';
    src: url('../assets/fonts/Tungsten-Black.eot');
    src: url('../assets/fonts/Tungsten-Black.woff') format('woff'),
         url('../assets/fonts/Tungsten-Black.ttf') format('truetype'),
         url('../assets/fonts/Tungsten-Black.svg') format('svg');
    font-style: normal;
}

@font-face {
    font-family: 'Tungsten Medium';
    src: url('../assets/fonts/Tungsten-Medium.eot');
    src: url('../assets/fonts/Tungsten-Medium.woff') format('woff'),
         url('../assets/fonts/Tungsten-Medium.ttf') format('truetype'),
         url('../assets/fonts/Tungsten-Medium.svg') format('svg');
    font-style: normal;
}

@font-face {
    font-family: 'Tungsten Bold';
    src: url('../assets/fonts/tungsten-bold.eot');
    src: url('../assets/fonts/Tungsten-Bold.woff') format('woff'),
         url('../assets/fonts/tungsten-bold.ttf') format('truetype'),
         url('../assets/fonts/Tungsten-Bold.svg') format('svg');
    font-style: normal;
}

@font-face {
    font-family: 'Khand Semibold';
    src: url('../assets/fonts/khand-semibold.eot');
    src: url('../assets/fonts/Khand-Semibold.woff') format('woff'),
         url('../assets/fonts/khand-semibold.ttf') format('truetype'),
         url('../assets/fonts/Khand-Semibold.svg') format('svg');
    font-style: normal;
}

@font-face {
    font-family: 'Khand Medium';
    src: url('../assets/fonts/khand-medium.eot');
    src: url('../assets/fonts/Khand-Medium.woff') format('woff'),
         url('../assets/fonts/khand-medium.ttf') format('truetype'),
         url('../assets/fonts/Khand-Medium.svg') format('svg');
    font-style: normal;
}

@font-face {
    font-family: 'Sports Fox';
    src: url('../assets/fonts/SportsFOXSportsUScore.eot');
    src: url('../assets/fonts/SportsFOXSportsUScore.woff') format('woff'),
         url('../assets/fonts/SportsFOXSportsUScore.ttf') format('truetype'),
         url('../assets/fonts/SportsFOXSportsUScore.svg') format('svg');
    font-style: normal;
}

@font-face {
    font-family: 'Khand Regular';
    src: url('../assets/fonts/khand-regular.eot');
    src: url('../assets/fonts/Khand-Regular.woff') format('woff'),
         url('../assets/fonts/khand-regular.ttf') format('truetype'),
         url('../assets/fonts/Khand-Regular.svg') format('svg');
    font-style: normal;
}

$font-tungsten-black: 'Tungsten Black', sans-serif;
$font-tungsten-medium: 'Tungsten Medium', sans-serif;
$font-tungsten-bold: 'Tungsten Bold', sans-serif;
$font-sports-fox: 'Sports Fox', sans-serif;
$font-khand-semibold: 'Khand Semibold', sans-serif;
$font-khand-medium: 'Khand Medium', sans-serif;
$font-khand-regular: 'Khand Regular', sans-serif;