.fox-logo {
	width: 70%;
	max-width: 200px;
	margin-bottom: -$gutter*2;
}

.prices-image {
	width: 150%;
	max-width: 700px;
	position: relative;	
	z-index: -1;
	margin: -150px 0;
	transform-origin: 50% 50%;
}

.bedankt-image {
	width: 100%;
	position: relative;
	z-index: -1;
	height: 250px;
	margin-top: -5px;

	div {
		background-position: top;
		background-size: contain;
		height: 100%;
	}
}