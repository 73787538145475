.cookie-bar {
	background-color: #133d61;
	position: fixed;
	top: 100%;
	width: 100%;
	padding: $gutter;
	max-width: 25rem;
	left: 50%;
	transform: translate(-50%, -100%);
	font-size: 14px;
	z-index: 3;
	letter-spacing: normal;
	text-align: left;
	vertical-align: middle;
	transition: transform $time-fast ease-in-out;
	display: flex;
	align-items: center;

	img {
		height: 20px;
		width: 20px;
		margin-right: $gutter*2;
		cursor: pointer;
	}

	a {
		font-size: 14px;
		font-family: $font-khand-regular;
		text-transform: none;
		text-decoration: underline;
		letter-spacing: normal;
		text-align: left;
		vertical-align: middle;

		&:before {
			content: none;
		}
	}
}

.cookie-disable {
	transform: translate(-50%, 0);
}