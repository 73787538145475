.blue-bg {
	position: relative;
	max-height: 230px;

	&:before {	
		content: '';
		width: 100vw;
		max-width: 25rem;
		height: calc( 100% + 2rem);
		background: rgb(0,54,93); /* Old browsers */
		background: -moz-linear-gradient(top, rgba(0,54,93,1) 0%, rgba(2,32,67,1) 100%); /* FF3.6-15 */
		background: -webkit-linear-gradient(top, rgba(0,54,93,1) 0%,rgba(2,32,67,1) 100%); /* Chrome10-25,Safari5.1-6 */
		background: linear-gradient(to bottom, rgba(0,54,93,1) 0%,rgba(2,32,67,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00365d', endColorstr='#022043',GradientType=0 ); /* IE6-9 */
		position: absolute;
		left: -$gutter;
		padding-top: $gutter;
		top: -$gutter;
		z-index: -1;
	}
}