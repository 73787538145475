.price-item {
	background-color: white;
	margin: $gutter/2 0;
	display: flex;
	justify-content: flex-start;
	width: 100%;
	align-items: center;
	padding: $gutter;	
	border-radius: 3px;
	box-shadow: 5px 10px 20px rgba(0,0,0,0.5);

	img {
		max-width: 25%;
		max-height: 100px;
		margin-right: $gutter;
	}

	h3 {
		text-align: left;
		color: #144471;
		text-shadow: none;
		line-height: 1;
		margin-bottom: 0;
		font-size: 18px;
		letter-spacing: 1px;
	}

	p {
		text-align: left;
		color: black;
		text-shadow: none;
		font-size: 15px; 
		margin-top: 2px;
		line-height: 19px;
		letter-spacing: 0px;
	}
}