// ========================================================

// # HELPERS

.list { list-style: none; list-style-image: none; padding-left: 0; }
.accessibility, .js .js-accessibility { clip: rect(1px, 1px, 1px, 1px); height: 1px; overflow: hidden; position: absolute; width: 1px; }
.accessibility:focus, .accessibility:active { clip: auto; height: auto; overflow: visible; position: fixed; width: auto; }
.group:before, .group:after { content: " "; display: table; }
.group:after { clear: both; }
.fieldset { @extend .group; }
.skip-to-main { left:-999px; position: absolute; top: auto; width: 1px; height: 1px; overflow: hidden; z-index: -9999; }
.skip-to-main:focus, .skip-to-main:active {color: #FFF; background-color: #000; left: 0; top: 0; overflow: auto; z-index: 999; }

.no-shadow {
	box-shadow: none;
	text-shadow: none;
}

.align-left {
	text-align: left;
}

.underline-black {
	&:before {
		background-color: black !important;
	}
}

.underline-gold {
	&:before {
		background-color: #bc9229 !important;
	}
}

.flex-valign {
	margin-top: auto !important;
	margin-bottom: auto !important;
}

.underline {
	position: relative;
	width: initial;

    &:before {
        content: '';
        position: absolute;
        width: 100%;
        background-color: white;
        bottom: -3px;
        left: 0;
        height: 1px;
    }
}

.big-margin-top {
	margin-top: $gutter*1.5;
}

.no-margin-bottom {
	margin-bottom: 0;
}

.checkbox-text {
	margin: $gutter 0;
	
	label {
		margin-right: $gutter;
		margin-top: -4px;
		&:before {
			border: 3px solid #a3d2f3;
		}
	}

	align-items: center;
	display: flex; 
}